<template>
  <div>
    <!-- <div class="header-list bg-multi">
      <p class="name">{{ name }}</p>
      <p class="number">เลขที่สัญญา: {{ number }}</p>

      <b-img src="@/assets/skl-logo.png" class="my-3 img-header-list" contain />
    </div> -->

    <div class="header-bg display-inline">
      <div class="info">
        <div class="display-inline line-info">
          <div class="cir-icon"><b-icon icon="person-fill"></b-icon></div>
          <p class="mb-0 w-100">{{ name || "-" }}</p>
        </div>
        <div class="display-inline line-info">
          <div class="cir-icon"><b-icon icon="file-earmark-fill"></b-icon></div>
          <p class="mb-0 w-100">เลขที่สัญญา: {{ number || "-" }}</p>
        </div>
      </div>
      <div class="img py-0">
        <b-img :src="imgUrl" class="img-header-profile" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListHeader",
  props: {
    name: {
      required: false,
      type: String
    },
    number: {
      required: false,
      type: String
    },
    imgUrl: {
      required: false,
      type: String
    }
  }
};
</script>

<style scoped>
/* header */
.header-list {
  position: relative;
  margin-bottom: 3rem;
  height: 150px;
  border-radius: 20px 20px 0px 0px;
}
.bg-multi {
  /* background: linear-gradient(
        310deg,
        #7fc241 15vh,
        #dee2e6 15vh,
        #dee2e6 20vh,
        white 20vh,
        white 30vh,
        #dee2e6 30vh,
        #dee2e6 40vh,
        #f48139 40vh,
        #f48139 100vh
      )
      0 0/100% calc(100% - 30px) no-repeat,
    linear-gradient(
      310deg,
      #7fc241 20vh,
      #dee2e6 17.5vh,
      #dee2e6 23.4vh,
      white 23.4vh,
      white 33.4vh,
      #dee2e6 32.2vh,
      #dee2e6 40vh,
      #dee2e6 40vh,
      #dee2e6 100vh 
    ); */

  background: linear-gradient(
        310deg,
        #7fc241 0px,
        #7fc241 110px,
        #dee2e6 110px,
        #dee2e6 140px,
        white 140px,
        white 220px,
        #dee2e6 220px,
        #dee2e6 270px,
        #f48139 270px,
        #f48139 100%
      )
      0 0/100% calc(100% - 40px) no-repeat,
    linear-gradient(
      310deg,
      #7fc241 0px,
      #7fc241 136px,
      #dee2e6 136px,
      #dee2e6 166px,
      white 166px,
      white 246px,
      #dee2e6 246px,
      #dee2e6 296px,
      #dee2e6 296px,
      #dee2e6 100%
    );
}
.img-header-list {
  position: absolute;
  bottom: -50px;
  right: 30px;
  border: 1px black solid;
  height: 120px;
  width: 120px;
}
.name {
  padding: 3rem 0rem 0rem 3rem;
  font-weight: bold;
}
.number {
  padding: 2rem 0rem 1rem 3rem;
  font-weight: bold;
}

/* fix UI */
.img-header-profile {
  border-radius: 50%;
  height: 70px;
  width: 70px;
}
.cir-icon {
  color: #f48139;
  background: white;
  padding: 1px 5px;
  margin-right: 20px;
  border-radius: 50%;
  align-self: center;
}
.display-inline {
  display: inline-flex;
}
.info {
  width: 75%;
  display: grid;
}
.img {
  width: 25%;
  align-self: center;
  text-align-last: right;
  padding-right: 10px;
}
.header-bg {
  margin: 1rem 0 1rem 0;
  width: 100%;
  background: linear-gradient(
    to bottom,
    #f48139 0%,
    #f48139 50%,
    #dee2e6 50%,
    #dee2e6 100%
  );
  border-radius: 20px 20px 0px 0px;
}
.line-info {
  align-items: center;
  padding: 10px 0px 10px 15px;
}
p {
    font-size: 14px;
  }
  span {
    font-size: 14px;
  }

@media (max-width: 767.98px) {
  .img-header-list {
    bottom: -30px;
    height: 100px;
    width: 100px;
  }
  .bg-multi {
    background: linear-gradient(
          310deg,
          #7fc241 0px,
          #7fc241 50px,
          #dee2e6 50px,
          #dee2e6 70px,
          white 70px,
          white 130px,
          #dee2e6 130px,
          #dee2e6 170px,
          #f48139 170px,
          #f48139 100%
        )
        0 0/100% calc(100% - 40px) no-repeat,
      linear-gradient(
        310deg,
        #7fc241 0px,
        #7fc241 75px,
        #dee2e6 75px,
        #dee2e6 96px,
        white 96px,
        white 155px,
        #dee2e6 155px,
        #dee2e6 100%
      );
  }
  /* p {
    font-size: 8px;
  }
  span {
    font-size: 8px;
  } */
  /* fix UI */
  .img-header-profile {
    height: 60px;
    width: 60px;
  }
  .img {
    width: 30%;
  }
}
</style>
